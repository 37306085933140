// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import 'slick-carousel';

import TableResponsive from '../_modules/utils/table-responsive/table-responsive';
// import SelectClone from '../_modules/utils/select-clone/select-clone';
import BackToTop from '../_modules/utils/back-to-top/back-to-top';
import SubscribeValidation from '../_modules/subscribe-validation/subscribe-validation';

import ExhibitionItem from '../_modules/atoms/exhibition-item/exhibition-item';
import Tabs from '../_modules/utils/tabs/tabs';

import ResourceCard from '../_modules/molecules/resource-card/resource-card';
import BlogFilter from '../_modules/molecules/blog-filter/blog-filter';
import AssociatedEvents from '../_modules/molecules/associated-events/associated-events';
import MediaComponent from '../_modules/molecules/media-component/media-component';

import Notification from '../_modules/organisms/notification/notification';
import SiteHeader from '../_modules/organisms/site-header/site-header';
import MegaMenuItem from '../_modules/organisms/mega-menu-item/mega-menu-item';
import Banner from '../_modules/organisms/banner/banner';
import Timeline from '../_modules/organisms/timeline/timeline';
import SearchModule from '../_modules/organisms/search-module/search-module';
import ExhibitionAnchor from '../_modules/organisms/exhibition-anchor/exhibition-anchor';

// Residencies JS
import ResidentsCard from '../_modules/atoms/residents-card/residents-card';
import ReadMore from '../_modules/molecules/read-more/read-more';
import FeatureResidentsNews from '../_modules/organisms/feature-residents-news/feature-residents-news';

$(() => {
  //Polyfill for object-fit
  objectFitImages();

  // Apply wrapper for table
  if ($('table').length) {
    new TableResponsive();
  }

  new SiteHeader();
  new Notification();
  new MegaMenuItem();
  new Banner();

  if ($('.tabs').length) {
    new Tabs();
  }
  // new SelectClone();

  if ($('.newsletter').length) {
    new SubscribeValidation();
  }

  if ($('.blog-filter').length) {
    new BlogFilter();
  }

  if ($('.exhibition').length) {
    new ExhibitionItem();
  }

  if ($('.more-resource').length) {
    new ResourceCard();
  }

  if ($('.associated-events').length) {
    new AssociatedEvents();
  }

  new Timeline();

  if ($('.search-result').length) {
    new SearchModule();
  }

  $('.match-height').matchHeight();

  // Scroll to anchor
  // If hash exists on load
  if (window.location.hash) {
    scrollToTarget(window.location.hash);
  }
  // On anchor click
  $('.navigation__link').on('click', function () {
    let target = $(this).attr('href');
    scrollToTarget(target);
  });

  function scrollToTarget(target) {
    try{
      let offset = $('.site-header').outerHeight();
      let hashIndex = target.search('#');
      let $hashTarget = $(target.slice(hashIndex));
      $('html, body')
        .stop(true, true)
        .animate(
        {
          scrollTop: $hashTarget.offset().top - offset,
        },
          500
        );
    }
    catch(err){
      console.log(err);
    }
    
  }

  $('a[target="_blank"]').map((i, ele) => {
    let $this = $(ele);

    $this.attr('rel', 'noopener noreferrer');
  });

  // Residencies Template
  if ($('.residents').length) {
    new ResidentsCard();
  }

  if ($('.readmore').length) {
    let $readmore = $('.readmore');

    $readmore.map((i, el) => {
      let $el = $(el);
      new ReadMore($el);
    });
  }

  if ($('.video-component').length) {
    new MediaComponent();
  }

  if ($('.back-to-top').length) {
    $('.back-to-top').map((i, ele) => {
      new BackToTop($(ele));
    });
  }

  if ($('.exhibition-anchor').length) {
    $('.exhibition-anchor').map((i, ele) => {
      new ExhibitionAnchor($(ele));
    });
  }

  //   if ($(".residencies-listing").length) {
  //     new FeatureResidentsNews();
  //   }

  $('.collection_carousel').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: true,
    prevArrow:
      '<button class="slick-prev slick-arrow" type="button"><i class="icon-chevron-thin-left"></i></button>',
    nextArrow:
      '<button class="slick-next slick-arrow" type="button"><i class="icon-chevron-thin-right"></i></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '32px',
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          centerPadding: '32px',
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  });

  // Blog select value redirect to new window
  let $blogSelect = $('#dynamic_select .custom-select__options ul li');
  $blogSelect.on('click', function (e) {
    let $this = $(this);
    let $val = $this.attr('rel');
    e.stopPropagation();
    if ($val) {
      // window.open($val, '_blank'); // redirect
      location.assign($val);
    }
    return false;
  });

  let $collectionCarousel = $('.collection_carousel_sec .collection_item');
  $collectionCarousel.on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    let $val = $this.attr('url');
    let $isNewTab = $this.attr('target');
    if ($val) {
      if ($isNewTab === '_blank') {
        window.open($val, '_blank');
      }
      else {
        location.assign($val);
      }
    }
  });
});
