'use strict';

import $ from 'jquery';
import 'slick-carousel';
import enquire from 'enquire.js';

export default class Timeline {
	constructor() {
		let $timeline = $('.timeline'),
			$timelineCarousel = $('.timeline__carousel', $timeline),
			$timelineItem = $('.timeline-item', $timeline),
			$timelineResponsiveContainer = $('.timeline__nav--responsive', $timeline),
			timelineData = {},
			yearsList = [];

		// Create list of years to create year number indicator on the timeline
		$timelineItem.map((i,ele) => {
			yearsList.push($(ele).data('year'));
		});

		// Remove duplicates from year list
		yearsList = [...new Set(yearsList)];

		// Create timeline data object
		yearsList.forEach((year, index) => {
			timelineData[year] = [];
		});

		$timelineItem.map((i, ele) => {
			let itemYear = $(ele).data('year'),
				itemTitle = $(ele).attr('id');

			timelineData[itemYear].push(itemTitle);
		});

		// Get largest and smallest year
		let largest, smallest;

		for (let i = 0; i < yearsList.length; i++) {
			if (i == 0){
				largest = parseInt(yearsList[0]);
				smallest = parseInt(yearsList[0]);
			}
			else {
				if(parseInt(yearsList[i]) > largest){
					largest = parseInt(yearsList[i]);
				}

				if(parseInt(yearsList[i]) < smallest){
					smallest = parseInt(yearsList[i]);
				}
			}
		}

		// Get total milestone items in latest year
		let totalLatest = $(`.timeline-item[data-year=${largest}]`).length,
			endReduction = 0;

		if(totalLatest > 1){
			endReduction = (30 * (totalLatest - 1));
		}

		// START: Generate DOM for timeline
		let $timelineNav = $('.timeline__nav', $timeline),
			$timelineNavUl = $('.timeline__nav ul', $timeline),
			slideIndex = 0;

		// Create list item markup for each year
		yearsList.forEach((year, index) => {
			// Calculate position
			let realTotalWidth = 902 - endReduction;
			let leftPosition = (year - smallest) / (largest - smallest) * realTotalWidth;

			let _li = `<li id=${year} style="left:${leftPosition}px;"><span class="year">${year}</span>`;

			timelineData[year].forEach((item, index) => {
				let _dot = `<div class="timeline__nav--dots" data-slideindex=${slideIndex}><div class="dot"><span class="visuallyHidden">${item}</span></div></div>`;
				_li += _dot;

				// Slide index increment
				slideIndex++;
			});

			_li += `</li>`;

			$timelineNavUl.append(_li);
		});

		if (totalLatest > 1) {
			let lastLiWidth = 30 * totalLatest;

			$(`#${largest}`).css('width', lastLiWidth);
		}

		// Carousel initialization
		$timelineCarousel.slick({
			centerMode: true,
			centerPadding: '85px',
			slidesToShow: 3,
			speed: 500,
			rows: 0,
			arrows: true,
			prevArrow: '<button class="slick-prev slick-arrow" type="button"><i class="icon-chevron-thin-left"></i></button>',
			nextArrow: '<button class="slick-next slick-arrow" type="button"><i class="icon-chevron-thin-right"></i></button>',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						centerPadding: '32px',
						slidesToShow: 2,
						arrows: false
					}
				},
				{
					breakpoint: 540,
					settings: {
						centerPadding: '32px',
						slidesToShow: 1,
						arrows: false
					}
				}
			]
		});

		// Set first to active
		$('.timeline__nav--linefill').css('width', `0%`);
		$('li', $timelineNavUl).first().addClass('active');
		$('li', $timelineNavUl).find('.timeline__nav--dots').first().addClass('active');

		$timelineCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			let $nextSlide = $(`.timeline-item[data-slick-index=${nextSlide}]`);

			if (!$(`.timeline__nav--dots[data-slideindex=${nextSlide}]`).hasClass('active')) {
				// Find offset for dot
				let leftEnd = $timelineNav.offset().left,
					rightEnd = leftEnd + $timelineNav.width(),
					dotPosition = $(`.timeline__nav--dots[data-slideindex=${nextSlide}]`).offset(),
					browserWidth = $(window).width();

				let leftFillLength = (dotPosition.left + 15 - leftEnd) / $timelineNav.width() * 100,
					castString = String(leftFillLength);


				let containerOffset = $timelineResponsiveContainer.scrollLeft(),
					centerPos = dotPosition.left + 15 + containerOffset - (browserWidth / 2);

				// Scroll the container to center the dot
				if(centerPos > 0){
					$timelineResponsiveContainer.animate({
						scrollLeft: `${centerPos}px`
					}, 500);
				}
				else {
					$timelineResponsiveContainer.animate({
						scrollLeft: "0px"
					}, 500);
				}

				$('.timeline__nav--linefill').css('width', `${castString}%`);

				setTimeout(function(){
					$('.timeline__nav--dots').removeClass('active');
					$(`.timeline__nav--dots[data-slideindex=${nextSlide}]`).addClass('active');
				}, 400);
			}

			let targetYear = $nextSlide.data('year');

			setTimeout(function () {
				if (!$(`#${targetYear}`).hasClass('active')) {
					$timelineNavUl.find('li.active').removeClass('active');
					$(`#${targetYear}`).addClass('active');
				}
			}, 400);
		});

		enquire.register("screen and (min-width: 1024px)", {
			match: () => {
				$('.timeline__nav--dots').map((i,ele) => {
					let $this = $(ele);

					$this.on('click', function() {
						let slideIndex = $(this).data('slideindex');

						$timelineCarousel.slick('slickGoTo', slideIndex);
					});
				});
			},
			unmatch: () => {
				$('.timeline__nav--dots').off('click');
			}
		});

	}
}
