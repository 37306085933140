'use strict';

import $ from 'jquery';

export default class SubscribeValidation {
	constructor() {
		let $newsletter = $('.newsletter'),
			$form = $('form', $newsletter);

		let checked = 0;

		$('.checkbox label').on('click', function (e) {
			checked = $('input[type="checkbox"]:checked').length;

			if($(this).prev().is(':checked')){
				if (checked == 1) {
					return false;
				}
			}
		});

		$('input[type="submit"]', $form).on('click', function(e){
			e.preventDefault();

			if(validate()){
				$form.trigger('submit');
			}
			else {
				// alert('Please fill in required fields.');
				return;
			}
		});

		function validate(){
			let validated = false;

			// validate first name
			let fname = $('input[name="FNAME"]').val(),
				fnameRegexPattern = $('input[name="FNAME"]').data('val-multiregex-pattern'),
				fnameRegex = new RegExp(fnameRegexPattern),
				fnameValidated = false;

			if(!fname){
				$('input[name="FNAME"]').addClass('input-validation-error');
			}
			else {
				if (!fnameRegex.test(fname)) {
					$('input[name="FNAME"]').addClass('input-validation-error');
				}
				else {
					$('input[name="FNAME"]').removeClass('input-validation-error');
					fnameValidated = true;
				}
			}

			// validate last name
			let lname = $('input[name="LNAME"]').val(),
				lnameRegexPattern = $('input[name="LNAME"]').data('val-multiregex-pattern'),
				lnameRegex = new RegExp(lnameRegexPattern),
				lnameValidated = false;

			if (!lname) {
				$('input[name="LNAME"]').addClass('input-validation-error');
			}
			else {
				if (!lnameRegex.test(lname)) {
					$('input[name="LNAME"]').addClass('input-validation-error');
				}
				else {
					$('input[name="LNAME"]').removeClass('input-validation-error');
					lnameValidated = true;
				}
			}

			// validate email
			let email = $('input[name="EMAIL"]').val(),
				regexPattern = $('input[name="EMAIL"]').data('val-regex-pattern'),
				emailRegex = new RegExp(regexPattern),
				emailValidated = false;

			if (!email) {
				$('input[name="EMAIL"]').addClass('input-validation-error');
			}
			else {
				if(!emailRegex.test(email)){
					$('input[name="EMAIL"]').addClass('input-validation-error');
				}
				else {
					$('input[name="EMAIL"]').removeClass('input-validation-error');
					emailValidated = true;
				}
			}

			// validate options
			let options = [];
			$('input[type="checkbox"]:checked').map((i, ele) => {
				let $this = $(ele);

				options.push($this.val());
			});

			let optionsValidated = false;

			if (!options.length){
				$('.sam-Newsletter_Interest.field-error').show();
			}
			else {
				$('.sam-Newsletter_Interest.field-error').hide();
				optionsValidated = true;
			}

			// Set flag
			if (fnameValidated && lnameValidated && emailValidated && optionsValidated) {
				validated = true;
			}

			return validated;
		}
	}
}
