'use strict';

import 'jquery-match-height';
import $ from 'jquery';

export default class AssociatedEvents {
  constructor() {
    let $associatedEvents = $('.associated-events'),
        typeView = 'data-type-view',
        listView = 'data-list-view',
        gridView = 'data-grid-view',
        $typeView = $associatedEvents.find('[' + typeView + ']');

    $typeView.find('[' + listView + ']').on('click', function(e) {
      e.preventDefault();
      var $this = $(this);
      var $parent = $this.parents('[' + typeView + ']');
      var items = $associatedEvents.find('[data-item]');
      var halfItems = Math.round(items.length/2);

      if ($parent.hasClass('active')) {
        return false;
      }

      $typeView.removeClass('active');
      $parent.addClass('active');
      $associatedEvents.addClass('associated-events--list-view');

      // items.filter(':lt(' + halfItems + ')').wrapAll('<div class="col-6"></div>');
      // items.filter(':gt(' + (halfItems - 1) + ')').wrapAll('<div class="col-6"></div>');

      // $('.more-resource', $associatedEvents).matchHeight();
      // Create the event.
      var event = document.createEvent('Event');

      // Define that the event name is 'build'.
      event.initEvent('viewChange', true, true);

      // Dispatch to DOM
      document.dispatchEvent(event);
      $('.residents-news__title').matchHeight();
    });

    $typeView.find('[' + gridView + ']').on('click', function(e) {
      e.preventDefault();
      var $this = $(this);
      var $parent = $this.parents('[' + typeView + ']');
      var items = $associatedEvents.find('[data-item]');

      if ($parent.hasClass('active')) {
        return false;
      }

      $typeView.removeClass('active');
      $parent.addClass('active');

      // items.unwrap('<div class="col-' + 6 + '"></div>');
      $('.events__list .col-6').contents().unwrap();
      $associatedEvents.removeClass('associated-events--list-view');

      // Create the event.
      var event = document.createEvent('Event');

      // Define that the event name is 'build'.
      event.initEvent('viewChange', true, true);

      // Dispatch to DOM
      document.dispatchEvent(event);
    });
  }
}
