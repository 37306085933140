'use strict';

import $ from 'jquery';

export default class ReadMore {
  constructor($selector) {
    let $readmore = $selector;
    let $readmoreTxt = $('.readmore-text', $readmore);
    let $btnWrap = $('.readmore-btn', $readmore);
    let $readmoreBtn = $('.btn--readmore', $btnWrap);

    $readmoreBtn.on('click', e => {
      e.preventDefault();
      $.when(
        $readmoreTxt.slideToggle(),
        $readmoreBtn.toggleClass('more')
      )
      .done(() => {
        if ($readmoreBtn.hasClass('more')) {
          $readmoreBtn.children('span').text('read less');
        } else {
          $readmoreBtn.children('span').text('read more');
        }
      });
    })
  }
}
