'use strict';

import $ from 'jquery';

export default class Tabs {
	constructor() {
		let $tabs = $('.tabs');

		$tabs.map((i, ele) => {
			let $this = $(ele);

			let $tabButtons = $('[data-tab-toggle]', $this),
				$tabsNav = $('.tabs__nav', $this),
			  	$tabsContent = $('.tabs__content', $this);

			$tabButtons.on('click', function(e){
				e.preventDefault();

				let target = $(this).attr('href'),
					label = $('span', $(this)).text();

				if(!$(this).parent().hasClass('active')){
					// Remove any active
					$('.active', $tabsNav).removeClass('active');
					// Set clicked to active
					$(this).parent().addClass('active');

					// Change label text
					$('.nav-toggle span', $this).text(label);

					// Close nav
					$tabsNav.removeClass('active');

					$('.active', $tabsContent).fadeOut(300, function(){
						$(this).removeClass('active');

						$(target, $tabsContent).fadeIn(300);
						$(target, $tabsContent).addClass('active');
					});
				}
				else {
					return;
				}
			});

			let $navToggle = $('.nav-toggle', $this);

			$navToggle.on('click', function(){
				if($tabsNav.hasClass('active')){
					$tabsNav.removeClass('active');
				}
				else {
					$tabsNav.addClass('active');
				}
			});
		});
	}
}
