'use strict';

import $ from 'jquery';
// import enquire from 'enquire.js';
import queryString from 'query-string';
import 'slick-carousel';
import Cookies from 'js-cookie';

export default class SiteHeader {
	constructor() {
		let $siteHeader = $('.site-header'),
			$notifcationBar = $('.site-header__notificationbar'),
			$searchBar = $('.site-header__searchbar', $siteHeader),
			$mobileHeader = $('.site-header__mobile', $siteHeader),
			$searchButton = $('#submit-search', $searchBar),
			$mobileCloseButton=$('#navbar-close',$siteHeader);

		// Buttons binder
		let $mobileMenuButton = $('.site-header__menu button', $siteHeader),
			$notificationButton = $('.site-header__notification button', $siteHeader),
			$notificationCloseButton = $('button', $notifcationBar),
			$searchBarButton = $('.site-header__search button', $siteHeader);


		// Sticky header
		$(window).on('scroll', function(){
			if ($(window).scrollTop() > 50) {
				$siteHeader.addClass('box-shadow');
			}
			else {
				$siteHeader.removeClass('box-shadow');
			}
		});

		$searchBarButton.on('click', function (e) {
			e.preventDefault();

			if ($notifcationBar.hasClass('active')) {
				$notifcationBar.removeClass('active');
				$notifcationBar.slideUp(300, function(){
					if (!$searchBar.hasClass('active')) {
						$searchBar.addClass('active');
						$searchBar.slideDown(300);
						$('input', $searchBar).focus();
					}
				});
			}
			else if ($mobileHeader.hasClass('active')) {
				$mobileHeader.removeClass('active');
				setTimeout(function(){
					$searchBar.addClass('active');
					$searchBar.slideDown(300);
					$('input', $searchBar).focus();
				}, 300);
			}
			else {
				if (!$searchBar.hasClass('active')) {
					$searchBar.addClass('active');
					$searchBar.slideDown(300);
					$('input', $searchBar).focus();
				} else {
					$searchBar.removeClass('active');
					$searchBar.slideUp(300);
				}
			}
		});

		$notificationButton.on('click', function (e) {
			e.preventDefault();

			if ($searchBar.hasClass('active')) {
				$searchBar.removeClass('active');
				$searchBar.slideUp(300, function(){
					if (!$notifcationBar.hasClass('active')) {
					  	$notifcationBar.addClass('active');
						$notifcationBar.slideDown(300);
						$('.site-header__notificationbar > div .carousel').slick('setPosition');
					}
				});
			}
			else if ($mobileHeader.hasClass('active')) {
				$mobileHeader.removeClass('active');
				setTimeout(function () {
					$notifcationBar.addClass('active');
					$notifcationBar.slideDown(300);
					$('.site-header__notificationbar > div .carousel').slick('setPosition');
				}, 300);
			}
			else {
				if (!$notifcationBar.hasClass('active')) {
				  	$notifcationBar.addClass('active');
					$notifcationBar.slideDown(300);
					$('.site-header__notificationbar > div .carousel').slick('setPosition');
				} else {
				  	$notifcationBar.removeClass('active');
				  	$notifcationBar.slideUp(300);
				}
			}
		});

		// Cookie removal for Debugging
		// Cookies.remove('notificationStatus');

		// Read cookie to check notification status
		if (!Cookies.get('notificationStatus')) {
			$notifcationBar.addClass('active');
			$notifcationBar.slideDown(300);
		}
		else {
			if (!Cookies.getJSON('notificationStatus').closed) {
				$notifcationBar.addClass('active');
				$notifcationBar.slideDown(300);
			}
		}

		$notificationCloseButton.on('click', function(e){
			e.preventDefault();

			$notifcationBar.removeClass('active');
			$notifcationBar.slideUp(300);
			Cookies.set('notificationStatus', { 'closed': true }, { expires: 7 });
		});
		$mobileMenuButton.on('click', function(e){
			e.preventDefault();

			if(!$mobileHeader.hasClass('active')){
				if ($searchBar.hasClass('active')) {
					$searchBar.removeClass('active');
					$searchBar.slideUp(300, function () {
						$mobileHeader.addClass('active');
					});
				}
				else if ($notifcationBar.hasClass('active')) {
					$notifcationBar.removeClass('active');
					$notifcationBar.slideUp(300, function () {
						$mobileHeader.addClass('active');
					});
				}
				else {
					$mobileHeader.addClass('active');
				}
			}
			else {
				$mobileHeader.removeClass('active');
			}

			// Close all opened navs
			setTimeout(function(){
				$('.nav-level1.active', $('.site-header__mobile--nav')).find('ul').hide();
				$('.nav-level1.active', $('.site-header__mobile--nav')).removeClass('active');
			}, 500);
		});
		
		$mobileCloseButton.on('click', function(e){
			e.preventDefault();
			$mobileHeader.removeClass('active');
		});

		// Update the notification badge
		// if ($('.site-header__notificationbar > p').length > 0) {
		// 	let count = 0;

		// 	for (let i = 0; i < $('.site-header__notificationbar > p').length; i++) {
		// 		count++;
		// 	}

		// 	$('.site-header__notification .badge').text(count);
		// }

		// Animate the notification bar badge
		if($('.site-header__notification .badge').text() == '0'){
			setTimeout(function(){
				$('.site-header__notification .badge').addClass('blinkin');
			}, 150);
		}
		else {
			setTimeout(function(){
				$('.site-header__notification .badge').addClass('popup');
			}, 550);
		}


		// Mobile menu accordion
		let $mobileNav = $('.site-header__mobile--nav'),
			$level1 = $('.nav-level1', $mobileNav),
			$level1Button = $('.nav-level1 > a > button', $mobileNav);

		$level1Button.on('click', function(e){
			e.preventDefault();
			e.stopPropagation();

			let $this = $(this),
				$li = $this.parent().parent(),
				$level2 = $this.parent().next();

			if(!$li.hasClass('active')){
				if($('.nav-level1.active', $mobileNav).length){
					$('.nav-level1.active', $mobileNav).find('ul').slideUp(300, function(){
						$li.addClass('active');
						$level2.slideDown(300);
					});
					$('.nav-level1.active', $mobileNav).removeClass('active');
				}
				else {
					$li.addClass('active');
					$level2.slideDown(300);
				}
			}
			else {
				$li.removeClass("active");
        		$level2.slideUp(300);
			}
		});

		// Submit search
		$searchButton.on('click', function(e){
			e.preventDefault();

			let searchQuery = {};

			searchQuery.query = $('#search-input').val();

			const stringified = queryString.stringify(searchQuery);
			const searchURL = $searchBar.data('searchurl');

			window.location = `${searchURL}?${stringified}`;
		});

		$('#search-input', $searchBar).keyup(function (e) {
			e.preventDefault();

			if (e.keyCode == 13 || e.which == 13) { // enter key maps to keycode `13`
				$searchButton.trigger('click');
			}
		});

		$('.nav-level1 > a').map((i, ele) => {
			let $this = $(ele);

			$this.on('mouseenter focus touchend', function(e){
				// $this.parent().parent().find('.on-focus').first().removeClass('on-focus');
				$('.nav-level1.on-focus').removeClass('on-focus');
				$this.parent().addClass('on-focus');
			});
			$this.on('mouseleave', function(e){
				$('.nav-level1.on-focus').removeClass('on-focus');
			});
		});

		$('.nav-level2 > a').map((i, ele) => {
			let $this = $(ele);

			$this.on('mouseenter focus touchend', function (e) {
				// $this.parent().parent().find('.on-focus').first().removeClass('on-focus');
				$('.nav-level2.on-focus').removeClass('on-focus');
				$this.parent().addClass('on-focus');
			});
			
			$this.on('mouseleave', function(e){
				$('.nav-level2.on-focus').removeClass('on-focus');
			});
		});
	}
}
