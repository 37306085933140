'use strict';

import $ from 'jquery';

export default class SearchModule {
	constructor() {
		let $filterToggle = $('.search-filter-toggle button');
		let $filters = $('.search__filters');

		$filterToggle.on('click', function(){
			if(!$(this).hasClass('active')) {
				$(this).addClass('active');
				$filters.slideDown(300);
			}
			else {
				$(this).removeClass('active');
				$filters.slideUp(300);
			}
		});

		if ($(window).width() < 768) {
			$filters.css('display', 'none');
		}

		$(window).on('resize', function(){
			if($(window).width() > 767){
				$filters.show();
			}
			else {
				$filters.hide();
				$filterToggle.removeClass('active');
			}
		});
	}
}
