'use strict';

import $ from 'jquery';

export default class Mediaomponent {
  constructor() {
    let $mediaComponent = $('.media-component');
    let $iframe = $('iframe', $mediaComponent);

    $iframe.map((i,ele) => {
      let $this = $(ele);
      $this.removeAttr('width').removeAttr('height');
    })
  }
}
