'use strict';

import $ from 'jquery';
export default class BackToTop {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;

    if (selfInit) this.init();
  }

  init() {
    $(window).on('scroll', () => {
      if ($(window).scrollTop() >= 250) {
        this.$selector.addClass('active');
      }
      else {
        this.$selector.removeClass('active');
      }
    });

    this.$selector.on('click', (e) => {
      e.preventDefault();

      $('html, body').animate({
        scrollTop: 0,
      });
    });
  }
}
