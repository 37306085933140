'use strict';

import 'jquery-match-height';
import $ from 'jquery';

export default class ExhibitionItem {
	constructor() {
		let $exhbitionItem = $('.exhibition');

		$('.exhibition__text-title', $exhbitionItem).matchHeight();
		$('.exhibition__text', $exhbitionItem).matchHeight();
		$('.exhibition__title', $exhbitionItem).matchHeight();
		$('.exhibition__time', $exhbitionItem).matchHeight();
		$('.exhibition__status', $exhbitionItem).matchHeight();
		$('.exhibition__info', $exhbitionItem).matchHeight();

		$('.exhibition-item__text', $exhbitionItem).matchHeight();

		document.addEventListener('fadedIn', function (e) {
			setTimeout(function(){
				$('.exhibition__text-title', $exhbitionItem).matchHeight._update();
				$('.exhibition__text', $exhbitionItem).matchHeight._update();
				$('.exhibition__title', $exhbitionItem).matchHeight._update();
				$('.exhibition__time', $exhbitionItem).matchHeight._update();
				$('.exhibition__status', $exhbitionItem).matchHeight._update();
				$('.exhibition__info', $exhbitionItem).matchHeight._update();

				$('.exhibition-item__text', $exhbitionItem).matchHeight._update();
			}, 1300);
		});

		document.addEventListener('ajaxEvent', function (e) {
			setTimeout(function(){
				let $exhbitionItem = $('.exhibition');

				console.log('match cards');
				$('.exhibition__text-title', $exhbitionItem).matchHeight();
				$('.exhibition__text', $exhbitionItem).matchHeight();
				$('.exhibition__title', $exhbitionItem).matchHeight();
				$('.exhibition__time', $exhbitionItem).matchHeight();
				$('.exhibition__status', $exhbitionItem).matchHeight();
				$('.exhibition__info', $exhbitionItem).matchHeight();

				$('.exhibition-item__text', $exhbitionItem).matchHeight();
			}, 1300);
		});
	}
}
