"use strict";

import $ from "jquery";
import "jquery-match-height";

export default class FeatureResidentsNews {
  constructor() {
    let $listingWrap = $(".residencies-listing");
    let $container = $(".container", $listingWrap);
    let $list = $(".events__list .row", $container);
    let $template = $container.data("event-template");

    let $residentsItem = $(".residents", $list);

    let $currentPage = 1;
    let $totalPage = 7; //- BE Hard-coded Value

    this.$endpoint = $container.data("event-url");

    this.$data = {
      page: 1,
      tag: "",
      cycle: "",
      offset: "",
    };

    // Init Slider Tabs
    this.initEventSlider($container);

    // Init Pagination
    this.drawPage($container, $currentPage, $totalPage);

    setTimeout(() => {
      // Filter Elements
      let $eventsFilterWrap = $(".events-filter", $container);
      let $sliderEventWrap = $(".slider-events", $container);
      let $showPageWrap = $(".result__show-page", $container);
      let $paginationWrap = $(".result__pagination", $container);

      let $select = $("select", $eventsFilterWrap);
      let $slider = $(".slider-events__item.slick-active", $sliderEventWrap);
      let $show = $(".show__link", $showPageWrap);
      let $pagination = $(
        ".pagination__item.active .pagination__link",
        $paginationWrap
      );

      let $customSelect = $(".custom-select__item", $eventsFilterWrap);

      console.log($customSelect.length);

      $customSelect.map((i, el) => {});
    }, 500);

    this.beforeGetData = () => {};

    this.getDataCallback = (res) => {
      // this.$data.page = '';
      // this.$data.tag = $select.val();
      // this.$data.cycle = '';
      // this.$data.offset = '';

      console.log(this.$data);
      console.log("loaded data");

      let getTemplate = ($template) => {
        return $($template).html();
      };

      window.Handlebars.registerHelper("inc", (value, options) => {
        return parseInt(value) + 1;
      });

      let templateHTML = getTemplate($template);
      let template = window.Handlebars.compile(templateHTML || "");
      let contentEl = template(res.results);

      $.when($list.empty())
        .done(() => {
          $list.append(contentEl);
        })
        .done(() => {
          setTimeout(() => {
            $(".residents__text-title", $residentsItem).matchHeight();
            // $('.residents__text', $residentsItem).matchHeight();
            $(".residents__title", $residentsItem).matchHeight();
            $(".residents-news", $container).matchHeight();
            $(".residents__time", $residentsItem).matchHeight();
            $(".residents__status", $residentsItem).matchHeight();
            $(".residents__info", $residentsItem).matchHeight();
            $(".residents-item__text", $residentsItem).matchHeight();
          }, 300);
        });
    };
  }

  getData(before = this.beforeGetData, cb = this.getDataCallback) {
    $.when(before()).done(() => {
      $.ajax({
        url: this.$endpoint,
        method: "GET",
        data: this.$data,
        success: (res) => {
          cb(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
    });
  }

  drawPage(el, $currPage, $totalPage) {
    var html = "",
      displayedPages = 5,
      valPage,
      active = "",
      elPaging = el.find("[data-paging-list]"),
      parentPaging = elPaging.parent(),
      elPrev = el.find("[data-paging-prev]"),
      elNext = el.find("[data-paging-next]"),
      elLoadMore = el.find("[data-paging-loadmore]").parent(),
      elContainer = el.find("[data-paging-container]");

    if ($totalPage > 1) {
      if (parseInt($currPage) <= displayedPages - 2) {
        var end = $totalPage < displayedPages ? $totalPage : displayedPages;
        for (var i = 1; i <= end; i++) {
          active = i === parseInt($currPage) ? "active" : "";

          valPage = i < 10 ? "0" + i : i;

          html +=
            '<li class="pagination__item ' +
            active +
            '"><a href="#" title="' +
            valPage +
            '" class="pagination__link" data-paging-item>' +
            valPage +
            "</a></li>";
        }
      } else {
        if (parseInt($currPage) >= $totalPage - 1) {
          var subPage = $totalPage - $currPage,
            start = $totalPage - displayedPages + subPage,
            endPage = $currPage - 2;
          if ($currPage === $totalPage) {
            start++;
          }

          for (var k = start; k < endPage; k++) {
            if (k > 0) {
              valPage = k < 10 ? "0" + k : k;

              html +=
                '<li class="pagination__item"><a href="#" class="pagination__link" title="' +
                valPage +
                '" data-paging-item>' +
                valPage +
                "</a></li>";
            }
          }
        }

        for (
          var j = parseInt($currPage) - 2, length = parseInt($currPage) + 2;
          j <= length && j <= $totalPage;
          j++
        ) {
          active = j === parseInt($currPage) ? "active" : "";

          valPage = j < 10 ? "0" + j : j;

          html +=
            '<li class="pagination__item ' +
            active +
            '"><a href="#" class="pagination__link" title="' +
            valPage +
            '" data-paging-item>' +
            valPage +
            "</a></li>";
        }
      }

      elPaging.html(html);
      parentPaging.removeClass("hidden");

      if (elLoadMore.hasClass("hidden") && site.isMobile() && $totalPage > 1) {
        elLoadMore.removeClass("hidden");
      }
    } else {
      parentPaging.addClass("hidden");
      if (!elLoadMore.hasClass("hidden") && site.isMobile()) {
        elLoadMore.addClass("hidden");
      }
    }

    if ($currPage > 1) {
      if (elPrev.hasClass("disabled")) {
        elPrev.removeClass("disabled");
      }
    } else {
      if (!elPrev.hasClass("disabled")) {
        elPrev.addClass("disabled");
      }
    }

    if ($currPage < $totalPage) {
      if (elNext.hasClass("disabled")) {
        elNext.removeClass("disabled");
      }
    } else {
      if (!elNext.hasClass("disabled")) {
        elNext.addClass("disabled");
      }
    }
  }

  initEventSlider(el) {
    console.log(el);
    var sliderDesktop = 1,
      sliderMobile = 1,
      dataSliderType = el.find("[data-slider-type]"),
      sliderType = dataSliderType.data("slider-type"),
      curSlide = el.find("[data-event-detail].current").index(),
      totalSlide = el.find("[data-event-detail]").length,
      valView = el.find("[data-filter-view]").val(),
      slidesToScroll = 1,
      elContainerSlider = el.find("[data-slider-event]");

    if (typeof sliderType !== "undefined") {
      sliderDesktop = dataSliderType.data("slider-" + sliderType + "-desktop");
      sliderMobile = dataSliderType.data("slider-" + sliderType + "-mobile");
    }

    if (!site.isMobile()) {
      var elSlider = el.find("[data-slider-event]");
      slidesToScroll = elSlider.data("slider-" + valView + "-desktop");

      if (totalSlide <= sliderDesktop) {
        curSlide = 0;
      } else {
        if (curSlide > Math.floor(sliderDesktop / 2)) {
          if (curSlide + Math.ceil(sliderDesktop / 2) < totalSlide) {
            curSlide = curSlide - Math.floor(sliderDesktop / 2);
          } else {
            curSlide = totalSlide - slidesToScroll;
          }
        } else {
          curSlide = 0;
        }
      }
    } else {
      if (typeof sliderType !== "undefined") {
        slidesToScroll = sliderMobile;
      }

      if (curSlide < 2) {
        curSlide = 0;
      }
    }

    elContainerSlider.slick({
      infinite: false,
      slidesToShow: sliderDesktop,
      slidesToScroll: slidesToScroll,
      initialSlide: curSlide,
      speed: 500,
      prevArrow:
        '<button type="button" class="slick-prev ico-left-circle"><span class="hidden">Previous</span></button>',
      nextArrow:
        '<button type="button" class="slick-next ico-right-circle"><span class="hidden">Next</span></button>',
      rows: 0,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: sliderMobile,
            slidesToScroll: slidesToScroll,
          },
        },
      ],
    });

    el.find(".slick-prev").click(function (e) {
      lastIndex = false;
      var currentSlide = elContainerSlider.slick("slickCurrentSlide");
      if (currentSlide < slidesToScroll) {
        elContainerSlider.slick("slickGoTo", 0);
      }
    });

    el.find(".slick-next").click(function (e) {
      var currentSlide = elContainerSlider.slick("slickCurrentSlide"),
        endCurrentSlide = slidesToScroll + currentSlide;

      if (
        totalSlide - (slidesToScroll + currentSlide) < slidesToScroll &&
        !lastIndex
      ) {
        elContainerSlider.slick("slickGoTo", totalSlide - slidesToScroll + 1);
        lastIndex = true;
      }
    });
  }
}
