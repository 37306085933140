'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class Notification {
	constructor() {
		let $notificationBar = $('.notification-true'),
			$notificationBarClone = $('.notification-clone'),
			$notificationCarousel = $('.notification-carousel-true', $notificationBar),
			$notificationCarouselClone = $('.notification-carousel-clone', $notificationBarClone);

		let $paging = $('.paging');

		if ($notificationBar.hasClass('has-indicator')){
			$notificationCarousel.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
				//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
				let i = (currentSlide ? currentSlide : 0) + 1;

				$paging.html('<p>' + i + '/' + slick.slideCount + '</p>');
			});
			// $notificationCarouselClone.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			// 	//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			// 	let i = (currentSlide ? currentSlide : 0) + 1;

			// 	$paging.html('<p>' + i + '/' + slick.slideCount + '</p>');
			// });
		}

		$notificationCarousel.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			arrows: true,
			autoplay: false,
			adaptiveHeight: true,
			asNavFor: '.notification-carousel-clone',
			prevArrow: '<button type="button" class="slick-prev prev"><i class="icon-chevron-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next next"><i class="icon-chevron-right"></i></button>'
		});

		$notificationCarouselClone.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			arrows: true,
			autoplay: false,
			adaptiveHeight: true,
			asNavFor: '.notification-carousel-true',
			prevArrow: '<button type="button" class="slick-prev prev"><i class="icon-chevron-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next next"><i class="icon-chevron-right"></i></button>'
		});

		// // Create space in main container
		// if ($notificationBar.hasClass('active')){
		// 	let notificationHeight = $notificationBar.outerHeight();

		// 	let _notificationSpace = `<div class="notification-space" style="height: ${notificationHeight}px;"></div>`;

		// 	$('main').prepend(_notificationSpace);
		// }

		// $(window).on('resize', function(){
		// 	let notificationHeight = $notificationBar.outerHeight();

		// 	$('.notification-space').css('height', `${notificationHeight}px`);
		// });
	}
}
