'use strict';

import 'jquery-match-height';
import $ from 'jquery';

export default class ResidentsCard {
  constructor() {
    let $residentsItem = $('.residents');
    let $filterType = $('.associated-filter__type', $residentsItem);

		$('.residents__text-title', $residentsItem).matchHeight();
		// $('.residents__text', $residentsItem).matchHeight();
    $('.residents__title', $residentsItem).matchHeight();
    // $('.residents-news__title', $residentsItem).matchHeight();
    $('.residents-news').matchHeight();
		$('.residents__time', $residentsItem).matchHeight();
		$('.residents__status', $residentsItem).matchHeight();
		$('.residents__info', $residentsItem).matchHeight();

    $('.residents-item__text', $residentsItem).matchHeight();
    
    document.addEventListener('fadedIn', function (e) {
			setTimeout(function(){
				$('.residents__text-title', $residentsItem).matchHeight._update();
				// $('.residents__text', $residentsItem).matchHeight._update();
        $('.residents__title', $residentsItem).matchHeight._update();
        // $('.residents-news__title', $residentsItem).matchHeight();
        $('.residents-news').matchHeight();
				$('.residents__time', $residentsItem).matchHeight._update();
				$('.residents__status', $residentsItem).matchHeight._update();
				$('.residents__info', $residentsItem).matchHeight._update();

				$('.residents-item__text', $residentsItem).matchHeight._update();
			}, 1300);
		});

		document.addEventListener('ajaxEvent', function (e) {
			setTimeout(function(){
				let $residentsItem = $('.residents');

				console.log('match cards');
				$('.residents__text-title', $residentsItem).matchHeight();
				// $('.residents__text', $residentsItem).matchHeight();
        $('.residents__title', $residentsItem).matchHeight();
        // $('.residents-news__title', $residentsItem).matchHeight();
        $('.residents-news').matchHeight();
				$('.residents__time', $residentsItem).matchHeight();
				$('.residents__status', $residentsItem).matchHeight();
				$('.residents__info', $residentsItem).matchHeight();

				$('.residents-item__text', $residentsItem).matchHeight();
			}, 1300);
    });
    
    if($('.associated-filter__type').length) {
      let $filterType = $('.associated-filter__type');
      let $viewType = $('.view-type__item', $filterType);

      $viewType.on('click', e => {
        e.preventDefault();
        $.fn.matchHeight._update();
      });
    }
  }
}
