"use strict";

import "jquery-match-height";
import $ from "jquery";

export default class ResourceCard {
  constructor() {
    let $resourceCard = $(".more-resource");

    $(".more-resource__title", $resourceCard).matchHeight();
    $(".more-resource__date", $resourceCard).matchHeight();
    $(".more-resource__text", $resourceCard).matchHeight();
    $(".card__name", $resourceCard).matchHeight();
    $(".card__title", $resourceCard).matchHeight();
    $(".card__date", $resourceCard).matchHeight();

    $resourceCard.matchHeight();

    document.addEventListener("fadedIn", function (e) {
      setTimeout(function () {
        // console.log('fade2');
        $(".more-resource__title", $resourceCard).matchHeight._update();
        $(".more-resource__date", $resourceCard).matchHeight._update();
        $(".more-resource__text", $resourceCard).matchHeight._update();
        $(".card__name", $resourceCard).matchHeight._update();
        $(".card__title", $resourceCard).matchHeight._update();
        $(".card__date", $resourceCard).matchHeight._update();

        $resourceCard.matchHeight._update();
      }, 1300);
    });

    document.addEventListener("ajaxEvent", function (e) {
      setTimeout(function () {
        let $resourceCard = $(".more-resource");

        console.log("match r-cards");
        $(".more-resource__title", $resourceCard).matchHeight();
        $(".more-resource__date", $resourceCard).matchHeight();
        $(".more-resource__text", $resourceCard).matchHeight();
        $(".card__name", $resourceCard).matchHeight();
        $(".card__title", $resourceCard).matchHeight();
        $(".card__date", $resourceCard).matchHeight();

        $resourceCard.matchHeight();
      }, 1300);
    });

    document.addEventListener("viewChange", function (e) {
      setTimeout(function () {
        let $resourceCard = $(".more-resource");

        console.log("match r-cards on view change");
        $(".more-resource__title", $resourceCard).matchHeight();
        $(".list-tagline", $resourceCard).matchHeight();
        $(".more-resource__date", $resourceCard).matchHeight();
        $(".more-resource__subtitle", $resourceCard).matchHeight();
        $(".more-resource__text", $resourceCard).matchHeight();
        $(".card__name", $resourceCard).matchHeight();
        $(".card__title", $resourceCard).matchHeight();
        $(".card__date", $resourceCard).matchHeight();

        $resourceCard.matchHeight();
      }, 500);
    });

    document.addEventListener("ajaxEventCalendar", function (e) {
      setTimeout(function () {
        let $resourceCard = $(".more-resource");

        console.log("match r-cards on event calendar load more");
        $(".more-resource__title", $resourceCard).matchHeight();
        $(".list-tagline", $resourceCard).matchHeight();
        $(".more-resource__date", $resourceCard).matchHeight();
        $(".more-resource__subtitle", $resourceCard).matchHeight();
        $(".more-resource__text", $resourceCard).matchHeight();
        $(".card__name", $resourceCard).matchHeight();
        $(".card__title", $resourceCard).matchHeight();
        $(".card__date", $resourceCard).matchHeight();

        $resourceCard.matchHeight();
      }, 500);
    });
  }
}
