'use strict';

import $ from 'jquery';
import queryString from 'query-string';

export default class BlogFilter {
	constructor() {
		let $blogFilter = $('.blog-filter');

		$('.clear-filters', $blogFilter).on('click', function (e) {
			e.preventDefault();

			$('.checkbox-custom').map((i,ele) => {
				$('input', $(ele)).prop('checked', false);
			});
		});

		$('.submit-filters').on('click', function(e){
			e.preventDefault();
			let $this = $(this);

			let searchParameters = {},
				parameterValueString = '';

			// Get checked input values in each option group
			$('input:checked', $blogFilter).map((i,ele) => {
				let $this = $(ele);

				// Ensure no comma at the start of parameterValueString
				if(i == 0){
					parameterValueString += `filter=${$this.val()}`;
				}
				else {
					parameterValueString += `&filter=${$this.val()}`;
				}
			});

			// Set parameter in query object
			// searchParameters['type'] = parameterValueString;

			// const searchQueryString = queryString.stringify(searchParameters, {
			// 	sort: false
			// });

			window.location.search = parameterValueString;
		});

		if (location.search) {
			this.parseQuerySelect(location.search);
		}
	}

	parseQuerySelect(urlSearchQuery) {
		const parsed = queryString.parse(urlSearchQuery);

		console.log(parsed);

		for (var key in parsed) {
			let checkedValues = parsed[key];

			for (let i = 0; i < checkedValues.length; i++) {
				$(`input[value='${checkedValues[i]}']`, $('.blog-filter')).prop('checked', true);
			}
		}
	}
}
