"use strict";

import $ from "jquery";
import "slick-carousel";
import enquire from "enquire.js";

export default class Banner {
  constructor() {
    let $homeBanner = $(".home-banner .carousel");

    let autoplay = true;

    enquire.register("screen and (max-width: 1024px)", {
      match: () => {
        autoplay = false;
      },
    });

    $homeBanner.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      autoplay: autoplay,
      autoplaySpeed: 3000,
      prevArrow:
        '<button type="button" class="slick-prev prev"><i class="icon-chevron-left"></i></button>',
      nextArrow:
        '<button type="button" class="slick-next next"><i class="icon-chevron-right"></i></button>',
      appendDots: $(".banner-dots"),
    });

    // const domVideo = $video.get(0);
    // const $videoMuteBtn = $("#videoMuteBtn");
    // const $videoPlayBtn = $("#videoPlayBtn");
    // const $videoForwardBtn = $("#videoSkipForward");
    // const $videoBackwardBtn = $("#videoSkipBackward");

    // $videoForwardBtn.on("click", (e) => {
    //   $video.get(0).currentTime += 10;
    // });

    // $videoBackwardBtn.on("click", (e) => {
    //   $video.get(0).currentTime -= 10;
    // });

    // $videoMuteBtn.on("click", (e) => {
    //   const $_this = $videoMuteBtn;
    //   domVideo.muted = !domVideo.muted;
    //   $_this.addClass("active");
    //   if (!domVideo.muted) {
    //     $_this.removeClass("active");
    //   }
    // });

    // $videoPlayBtn.on("click", () => {
    //   const $_this = $videoPlayBtn;
    //   if (domVideo.paused) {
    //     domVideo.play();
    //     $_this.addClass("active");
    //   } else {
    //     domVideo.pause();
    //     $_this.removeClass("active");
    //   }
    // });

    let $slickCurrent = $homeBanner.find(".slick-current"),
      $video = $slickCurrent.find("video"),
      $bannerVideos = $("video", $homeBanner);

    $bannerVideos.on("play", () => {
      $homeBanner
        .slick("slickSetOption", "autoplay", false)
        .slick("slickPause");
    });

    $bannerVideos.on("pause", () => {
      $homeBanner.slick("slickSetOption", "autoplay", true).slick("slickPlay");
    });

    $bannerVideos.on("ended", () => {
      $homeBanner.slick("slickSetOption", "autoplay", true).slick("slickPlay");
      $homeBanner.slick("slickNext");
    });

    //on page load if slick current has video, play video
    if ($video.length) {
      $video.get(0).play();
    }

    //reset video to start
    $homeBanner.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
      $slickCurrent = $homeBanner.find(".slick-current");
      $video = $slickCurrent.find("video");
      if ($video.length) {
        $video.get(0).currentTime = 0;
        $video.get(0).pause();
      }
    });

    //play video
    $homeBanner.on("afterChange", (event, slick, currentSlide, nextSlide) => {
      $slickCurrent = $homeBanner.find(".slick-current");
      $video = $slickCurrent.find("video");
      if ($video.length) {
        $video.get(0).play();
      }
    });
  }
}
